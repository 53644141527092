<template>
  <section>
    <v-app>
      <Header class="showInDesktop" />
      <Headermb class="showInMobile" />
      <router-view :key="$route.fullPath"></router-view>
      <Footer />
      <div class="whatsappfix">
        <a
          href="tel:+9779801855634"
          target="_blank"
          style="text-decoration: none"
        >
          <button style="background-color: #fff; color: #000;">
             +9779801855634
          </button>
        </a>
        <a
          href="https://wa.me/9801855634?text=Hi%20there!"
          target="_blank"
          style="text-decoration: none"
        >
          <button style="background-color: #25d366; color: white;border-color:#25d366">
            Say Hi WhatsApp
          </button>
        </a>
      </div>
    </v-app>
  </section>
</template>

<script>
import Header from "@/components/frontend/Header";
import Headermb from "@/components/frontend/Headermb";
import Footer from "@/components/frontend/Footer";
import { mapActions } from "vuex";
export default {
  name: "Frontend",
  components: {
    Header,
    Headermb,
    Footer,
  },
  data: () => ({}),
  methods: {
    ...mapActions(["logout", "fillUserData"]),
    logoutbutton() {
      this.logout();
    },
  },
  created() {
    if (localStorage.getItem("token")) {
      this.fillUserData();
    }
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables.scss";
.logobox {
  margin: 10px;
}
.backmenu {
  background: $menucolor !important;
  .v-list-item__title {
    color: $white;
  }
  .v-icon {
    color: $white;
  }
}

.rightsection {
  display: flex;
  width: 100%;
  list-style: none;
  padding-left: 0;
  justify-content: flex-end;
  align-items: center;
}
.whiteicon {
  color: $white !important;
}
.userIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  width: 40px;
  border: 4px solid $white;
  background: $tagcolor;
  border-radius: 50%;
  color: $white;
  margin-right: 10px;
  font-weight: 600;
}
.leftsection {
  display: flex;
  justify-content: flex-start;
  list-style: none;
  padding-left: 0;
  li {
    margin-right: 10px;
    &:first-of-type {
      .v-btn {
        background: rgb(67, 82, 67);
        color: $white;
      }
    }
    &:last-of-type {
      .v-btn {
        background: rgb(3, 92, 3);
        color: $white;
      }
    }
  }
}
.iconclock {
  margin-right: 10px;
  color: $white !important;
}
.showInDesktop {
  @media only screen and (max-width: 991px) {
    display: none;
  }
  @media only screen and (min-width: 991px) {
    display: block;
  }
}
.showInMobile {
  @media only screen and (min-width: 991px) {
    display: none;
  }
  @media only screen and (max-width: 991px) {
    display: block;
  }
}
.whatsappfix {
  position: fixed;
  top: 50%;
  transform: translateY(-60%);
  left: -60px;
  display: flex;
  flex-flow: column;
  gap:200px;
  button {
    transform: rotate(-90deg);
    border: none;
    padding: 10px 20px;
    font-size: 16px;
    border-radius: 5px;
    cursor: pointer;
    border:1px solid #efefef;
  }
}
</style>
