<template>
  <section>
    <div class="topsection">
      <v-container class="container-custom">
        <v-row>
          <ul class="authorizationlink">
            <li
              v-if="
                allLogin.token != undefined && allLogin.user.role != 'company'
              "
            >
              Logout
              <v-icon class="logout" @click="logoutFunction">
                mdi-power
              </v-icon>
              Welcome, {{ allLogin.user.fullname }}
            </li>
            <li
              v-if="
                allLogin.token != undefined && allLogin.user.role == 'company'
              "
            >
              Logout
              <v-icon class="logout" @click="logoutFunction">
                mdi-power
              </v-icon>
              <span v-if="allSingleCompany.results">
                Welcome,
                <span
                  v-if="
                    allSingleCompany.results.slug == null &&
                    allSingleCompany.results.slug == undefined
                  "
                  >{{ allLogin.user.fullname }} </span
                ><router-link
                  v-if="
                    allSingleCompany.results.slug != null &&
                    allSingleCompany.results.slug != undefined
                  "
                  :to="`/singeCompany/${allSingleCompany.results.slug}`"
                  >{{ allLogin.user.fullname }}
                </router-link></span
              >
            </li>
            <template v-if="allLogin.token != undefined">
              <li v-if="allLogin.user.role == 'admin'">
                <router-link to="/dashboard" class="dashboardlink"
                  >Dashboard</router-link
                >
              </li>
              <li v-if="allLogin.user.role == 'employee'">
                <router-link to="/dashboard" class="dashboardlink"
                  >Dashboard</router-link
                >
              </li>
              <li v-if="allLogin.user.role == 'user' && allResumeStatus >= 7">
                <span @click="viewResume">View Resume</span>
              </li>
            </template>

            <li v-if="allLogin.token == undefined">
              Job Seeker
              <ul class="dropdown">
                <li><router-link to="/login">Login</router-link></li>
                <li>
                  <router-link to="/registration">Register</router-link>
                </li>
              </ul>
            </li>
            <li v-if="allLogin.token == undefined">
              Employer
              <ul class="dropdown">
                <li><router-link to="/login">Login</router-link></li>
                <li>
                  <router-link to="/registercompany">Register</router-link>
                </li>
              </ul>
            </li>
          </ul>

          <ul class="sociallink">
            <li>
              <a href="https://www.facebook.com/Necojobs" target="_blank"
                ><v-icon> mdi-facebook </v-icon></a
              >
            </li>
            <li>
              <a
                href="https://www.linkedin.com/company/neco-jobs-pvt-ltd/"
                target="_blank"
                ><v-icon> mdi-linkedin </v-icon></a
              >
            </li>
            <li>
              <a href="https://x.com/Necojobs" target="_blank"
                ><v-icon> mdi-twitter </v-icon></a
              >
            </li>
            <li>
              <a href="https://www.instagram.com/necojobs/" target="_blank"
                ><v-icon> mdi-instagram </v-icon></a
              >
            </li>
            <li>
              <a href="https://www.youtube.com/@neco2019/shorts" target="_blank"
                ><v-icon> mdi-youtube </v-icon></a
              >
            </li>
          </ul>
        </v-row>
      </v-container>
    </div>
    <div class="navigation">
      <v-container class="container-custom">
        <v-row>
          <v-col md="2" class="logo">
            <router-link to="/">
              <img src="@/assets/necologo.png" alt="" />
            </router-link>
          </v-col>
          <v-col md="10" style="padding: 0">
            <ul class="navigationlist">
              <li>
                <router-link to="/"
                  ><v-icon> mdi-home </v-icon> Home</router-link
                >
              </li>
              <li class="listmenuCourse">
                <router-link to="/category"> Browse Jobs </router-link>
                <ul class="job-dropdown-list">
                  <li
                    v-for="(data, index) in allHomeCategoriesJob.results"
                    :key="index"
                  >
                    <img
                      v-if="data.image"
                      :src="'https://app.necojobs.com.np' + data.image"
                      alt=""
                      style="height: 20px; object-fit: contain; width: 10%"
                    />

                    <p class="jobs-count">
                      <router-link :to="`/category/${data.slug}`">
                        {{
                          data.title.length > 24
                            ? data.title.slice(0, 36) + "..."
                            : data.title
                        }}</router-link
                      >
                      <span>({{ data.jobs }})</span>
                    </p>
                  </li>
                </ul>
              </li>

              <li>
                <router-link to="/postajob"> Post A Job</router-link>
              </li>
              <li class="listmenuCourse">
                <router-link to="/training"> Training</router-link>
                <!-- {{ allCousesMenu }} -->
                <ul class="dropdown-list">
                  <li v-for="(ele, index) in allCousesMenu" :key="index">
                    <strong>
                      {{ ele.category.title }}
                    </strong>
                    <ul class="course-dropdown-list">
                      <li v-for="(course, index) in ele.courses" :key="index">
                        <router-link
                          :to="`/course/${course.slug}`"
                          class="course-title"
                          >{{ course.trainingTitle }}</router-link
                        >
                      </li>
                    </ul>
                    <!-- <router-link :to="ele.slug">{{ ele.title }}</router-link> -->
                  </li>
                </ul>
              </li>
              <li class="listmenu">
                Blogs
                <ul class="dropdown-list">
                  <li v-for="(ele, index) in blogmenus" :key="index">
                    <router-link :to="ele.slug">{{ ele.title }}</router-link>
                  </li>
                </ul>
              </li>
            </ul>
          </v-col>
        </v-row>
      </v-container>
    </div>

    <ResumeView :showbox="showbox" @closepopup="closepopup" />
  </section>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import ResumeView from "@/components/ResumeView";
export default {
  name: "Header",
  components: { ResumeView },
  computed: {
    ...mapGetters([
      "allLogin",
      "allFrontendMenus",
      "allSingleCompany",
      "allResumeStatus",
      "allCousesMenu",
      "allHomeCategoriesJob",
    ]),
  },

  data: () => ({
    showbox: true,
    menulist: [
      {
        title: "Home",
        slug: "/about",
        icon: "mdi-home",
      },
      {
        title: "About",
        slug: "/about",
      },
      {
        title: "Blog",
        slug: "/about",
      },
      {
        title: "Training",
        slug: "/about",
      },
      {
        title: "Find Jobs",
        slug: "/about",
      },
    ],
    drawer: false,
    group: null,
    blogmenus: [
      {
        title: "Career Tips",
        slug: "/blogs/career-tips",
      },
      {
        title: "Business Tips",
        slug: "/blogs/business-tips",
      },
      {
        title: "Information",
        slug: "/blogs/information",
      },
      {
        title: "Education",
        slug: "/blogs/education",
      },
    ],
  }),
  watch: {
    allHomeCategoriesJob: {
      immediate: true,
      handler(newValue) {
        if (!newValue || !newValue.results || newValue.results.length === 0) {
          this.getCategoriesHome();
        }
      },
    },
  },
  methods: {
    ...mapActions([
      "fetchMenusFrontend",
      "logout",
      "getCompany",
      "fillUserData",
      "getSingleCompanyUser",
      "singelResume",
      "getResumeStatus",
      "fetchCourseMenus",
      "getCategoriesHome",
      "getHomeJob",
    ]),
    logoutFunction() {
      this.logout();
    },
    viewResume() {
      this.singelResume("NJ-100000");
      this.showbox = true;
    },
    closepopup(e) {
      this.showbox = e;
    },
  },
  // async created() {
  //   this.showbox = false;
  //   this.fetchMenusFrontend();
  //   this.fillUserData();
  //   this.getHomeJob();
  //   if (
  //     this.allLogin.user != undefined &&
  //     this.allLogin.user.role == "company"
  //   ) {
  //     this.getSingleCompanyUser({ id: this.allLogin.user.userHandle });
  //   }
  //   if (this.allLogin.user != undefined) {
  //     await this.getResumeStatus();
  //   }
  //   await this.getCategoriesHome();
  //   await this.fetchCourseMenus();
  //   await this.getCategoriesHome();
  // },
  async created() {
    this.showbox = false;
    this.fillUserData();
    this.getHomeJob();
    if (
      this.allLogin.user != undefined &&
      this.allLogin.user.role == "company"
    ) {
      this.getSingleCompanyUser({ id: this.allLogin.user.userHandle });
    }
    if (this.allLogin.user != undefined) {
      await this.getResumeStatus();
    }
    await this.fetchCourseMenus();
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables.scss";
.dropdown-list {
  margin-left: 0;
  padding-left: 0;
  display: flex;
  flex-flow: column;
  position: absolute;
  background: $white;
  width: 200px;
  top: 100%;
  display: none;
  li {
    margin-left: 0;
    padding-left: 0;
    a {
      justify-content: flex-start !important;
      display: block;
      padding: 5px 10px;
    }
  }
}
.job-dropdown-list {
  margin-left: 0;
  padding-left: 0;
  display: flex;
  flex-flow: column;
  position: absolute;
  background: $white;
  top: 100%;
  display: none;
  li {
    margin-left: 0;
    display: flex;
    align-items: center;
    padding-left: 0;
    a {
      justify-content: flex-start !important;
      display: flex;
      padding: 0px 5px;
      font-size: 13px;
    }
    .icons {
      font-size: 16px;
    }
  }
}
.listmenu {
  position: relative;
  &:hover {
    .dropdown-list {
      li {
        padding: 0;
      }
      display: flex;
    }
  }
  &:hover {
    .job-dropdown-list {
      li {
        padding: 0;
      }
      display: flex;
    }
  }
}
.listmenuCourse {
  &:hover {
    .dropdown-list {
      z-index: 9999;
      position: absolute;
      background: #efefef;
      left: 0;
      right: 0;
      width: 100%;
      display: flex;
      flex-direction: row;
      padding: 10px;
      flex-wrap: wrap;
      box-sizing: border-box;
      > li {
        margin: 0;
        width: 25%;

        ul {
          width: 100%;
          flex: 1;
          display: flex;
          flex-direction: column;
          li {
            margin: 0;
            padding: 0;
            a {
              padding: 5px 0;
            }
          }
        }
      }
    }
  }
  &:hover {
    .job-dropdown-list {
      z-index: 9999;
      position: absolute;
      background: #efefef;
      left: 0;
      right: 0;
      width: 100%;
      display: flex;
      flex-direction: row;
      padding: 10px;
      flex-wrap: wrap;
      box-sizing: border-box;
      > li {
        margin: 0;
        width: 25%;

        ul {
          width: 100%;
          flex: 1;
          display: flex;
          flex-direction: column;
          li {
            margin: 0;
            padding: 0;
            a {
              padding: 5px 0;
            }
          }
        }
      }
    }
  }
}
.topsection {
  display: flex;

  .row {
    justify-content: flex-end;
  }
}
.authorizationlink {
  margin-right: 15px;
  padding-left: 0;
  margin-left: 0;
  & > li {
    position: relative;
    padding: 10px;
    font-size: $normalfont;
    color: $dark;
    cursor: pointer;
    &:hover {
      color: $tagcolor;
      .dropdown {
        display: flex;
      }
    }
  }
  .dropdown {
    display: none;
    flex-flow: column;
    padding-left: 0;
    margin-left: 0;
    box-shadow: 0px 1px 14px 1px rgba(222, 222, 222, 1);
    position: absolute;
    background: $white;
    top: 100%;
    z-index: 999;
    li {
      margin: 0;
      a {
        color: $dark;
        text-decoration: none;
        padding: 5px 15px;
        display: block;
        width: 150px;
      }
    }
  }
}

.navigation {
  box-shadow: 0px 1px 3px 1px rgb(190, 190, 190);
  .container-custom {
    position: relative;
  }
  .navigationlist {
    font-size: $normalfont;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    li {
      padding: 10px 0;
    }
    a {
      display: flex;
      align-items: center;
      justify-content: center;
      text-decoration: none;
      color: $dark;
      .v-icon {
        margin-right: 10px;
        font-size: $iconfont;
        color: $dark;
      }
      &:hover {
        color: $tagcolor;
        .v-icon {
          color: $tagcolor;
        }
      }
    }
  }
  .logo {
    position: relative;
    a {
      display: block;
      position: absolute;
      top: -35px;
    }
    img {
      width: 180px;
      background: $white;
      padding: 5px 0;
    }
  }
}

.sociallink {
  a {
    color: red;
  }
}
.dashboardlink {
  font-size: $normalfont;
  text-decoration: none;
}
.logout {
  font-size: $iconfont;
  margin-right: 10px;
}
a {
  color: $dark !important;
}

/* On screens that are 992px or less, set the background color to blue */
@media screen and (max-width: 992px) {
  .topsection {
    display: none;
  }
  .navigation .logo a {
    top: inherit;
  }
}
ul {
  list-style-type: none;
  padding: 0;

  li {
    display: inline-block;
    margin: 0 10px;
  }
}
.jobs-count {
  display: flex;

  a {
    &:hover {
      color: $tagcolor !important;
      text-decoration: underline !important;
    }
  }
}
.course-dropdown-list {
  li {
    .course-title {
      &:hover {
        color: $tagcolor !important;
        text-decoration: underline !important;
      }
    }
  }
}
</style>
