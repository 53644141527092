<template>
  <v-app id="app">
    <router-view :key="$route.fullPath"></router-view>
    <template v-if="allLogin.token != undefined">
        <v-snackbar v-model="snackbar">
          <span>
            Welcome {{ allLogin.user.fullname }}
          </span>
        </v-snackbar>
      </template>
  </v-app>
</template>
<script>
import { mapGetters } from 'vuex';
export default {
  name: "App",
  data: () => ({snackbar:false}),
  computed: {
    ...mapGetters(["allLogin"]),
  },
  created() {
    this.snackbar = true;
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/variables.scss";
html,body{
  padding: 0;
  margin: 0;
}
.filter-list{
  padding: 0!important;
  margin: 10px 0!important;
  .v-input__slot{
    padding: 0!important;
    margin: 0!important;
  }
  .v-input--selection-controls{
    padding: 0!important;
    margin: 0!important;
  }
  .v-input{
    padding: 0!important;
    margin: 0!important;
  }
  .v-messages{
    display: none;
  }
}

.not-found {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 50vh;
  text-align: center;
  background-color: #f8f8f8;
  color: #333;
  font-family: "Arial", sans-serif;
}

.not-found h1 {
  font-size: 6rem;
  margin: 0;
  font-weight: bold;
  color: #ff6b6b;
}

.not-found p {
  font-size: 1.5rem;
  margin: 10px 0 20px;
}

.home-link {
  padding: 10px 20px;
  border: 2px solid #ff6b6b;
  color: #ff6b6b;
  text-decoration: none;
  border-radius: 5px;
  transition: background-color 0.3s ease, color 0.3s ease;
  margin-top: 30px;
}

.home-link:hover {
  background-color: #ff6b6b;
  color: #fff;
}
</style>
